import React, { useState } from 'react';

const UserDashboard = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [editMode, setEditMode] = useState(false); // State to track edit mode

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleEditProfile = () => {
    setEditMode(true);
  };

  return (
    <div className="container mt-3">
      <h3 className="text-center mb-4">User Dashboard</h3>
      <div className="row">
        <div className="col-md-3">
          <div className="list-group">
            <button
              className={`list-group-item list-group-item-action ${
                activeTab === 'profile' ? 'active' : ''
              }`}
              onClick={() => handleTabChange('profile')}
            >
              User Profile
            </button>
            <button
              className={`list-group-item list-group-item-action ${
                activeTab === 'orderHistory' ? 'active' : ''
              }`}
              onClick={() => handleTabChange('orderHistory')}
            >
              Order History
            </button>
          </div>
        </div>
        <div className="col-md-9">
          {activeTab === 'profile' && (
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-4">User Profile</h5>
                {editMode ? (
                  <form>
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">First Name</label>
                      <input type="text" className="form-control" id="firstName" />
                    </div>
                    {/* Add more form fields for user profile */}
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">Last Name</label>
                      <input type="text" className="form-control" id="lastName" />
                    </div>
                    <button type="submit" className="btn btn-primary">Save Profile</button>
                  </form>
                ) : (
                  <>
                    {/* Show user details */}
                    <p><strong>First Name:</strong> John</p>
                    <p><strong>Last Name:</strong> Doe</p>
                    {/* Add more user details as needed */}
                    <button className="btn btn-secondary" onClick={handleEditProfile}>Edit Profile</button>
                  </>
                )}
              </div>
            </div>
          )}
          {activeTab === 'orderHistory' && (
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-4">Order History</h5>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Render order history items */}
                    <tr>
                      <th scope="row">1</th>
                      <td>12345</td>
                      <td>2022-03-15</td>
                      <td>$100.00</td>
                    </tr>
                    {/* Add more table rows for order history */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
