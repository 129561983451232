import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { decreaseQuantity, increaseQuantity, removeFromCart } from "../../store/cartSlice";
import { Link } from "react-router-dom";

const AddToCart = () => {
  const dispatch = useDispatch();

  const handleQuantityDecrease = (itemId) => {
    console.log("Decrease Quantity", itemId);
    dispatch(decreaseQuantity({ itemId }));
  };

  const handleQuantityIncrease = (itemId) => {
    console.log("Increase Quantity", itemId);
    dispatch(increaseQuantity({ itemId }));
  };

  const handleRemoveItem = (itemId) => {
    console.log("Remove Item", itemId);
    dispatch(removeFromCart({ itemId }));
  };

  const { cart } = useSelector((state) => ({
    cart: state.cartReducer.cart,
  }));

  const [shippingAddress, setShippingAddress] = useState("");

  const calculateTotalAmount = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.price * item.quantity;
    });
    return total;
  };

  const calculateShippingCharge = () => {
    const totalAmount = calculateTotalAmount();
    if (totalAmount >= 1000) {
      return 200; // Free shipping for orders over 1000
    } else {
      return 500; // Fixed shipping charge for other orders
    }
  };

  const calculateTotalQuantity = () => {
    let totalQuantity = 0;
    cart.forEach((item) => {
      totalQuantity += item.quantity;
    });
    return totalQuantity;
  };

  const totalAmount = calculateTotalAmount();
  const shippingCharge = calculateShippingCharge();
  const totalPriceWithShipping = totalAmount + shippingCharge;
  const totalQuantity = calculateTotalQuantity(); // Calculate total quantity

  return (
    <div className="container py-5">
      {cart.length === 0 ? (
        <div className="text-center">
          <h1>There are no items in this cart</h1>
          <Link to="/store" className="btn btn-primary mt-3">Continue Shopping</Link>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card shadow">
              <div className="card-body">
                <h1 className="fw-bold mb-4 text-center text-dark">
                  Shopping Cart
                </h1>
                {cart.map((item) => (
                  <div key={item.id} className="row mb-4 d-flex justify-content-between align-items-center">
                    <div className="col-md-2 col-lg-2 col-xl-2">
                      <img
                        src={item.productImage}
                        className="img-fluid rounded-3"
                        alt="Product"
                      />
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3">
                      <h6 className="text-muted">{item.category}</h6>
                      <h6 className="text-black mb-0">{item.name}</h6>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3 d-flex align-items-center">
                      <button
                        className="btn btn-link px-2"
                        onClick={() => handleQuantityDecrease(item.id)}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <input
                        id="form1"
                        min="1"
                        name="quantity"
                        value={item.quantity}
                        type="number"
                        className="form-control form-control-sm text-black"
                      />
                      <button
                        className="btn btn-link px-2"
                        onClick={() => handleQuantityIncrease(item.id)}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="col-md-2">
                      <h6 className="mb-0 text-black">NPR. {item.price}</h6>
                    </div>
                    <div className="col-md-1 text-end" onClick={() => handleRemoveItem(item.id)}>
                      <a href="#!" className="text-muted">
                        <i className="fa fa-times"></i>
                      </a>
                    </div>
                  </div>
                ))}
                <hr className="my-4" />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card shadow p-4">
              <h3 className="fw-bold mb-4 text-dark">Summary</h3>
              <h6 className="text-muted">Total quantity: {totalQuantity}</h6> {/* Use totalQuantity here */}
              <h6 className="text-muted">Shipping Charge: NPR. {shippingCharge.toFixed(2)}</h6>
              <h6 className="text-muted">Total price:</h6>
              <h3 className="fw-bold text-dark">NPR. {totalPriceWithShipping.toFixed(2)}</h3>
              <hr />
              <label htmlFor="shippingAddress" className="form-label mb-2">
                Shipping Address
              </label>
              <input
                id="shippingAddress"
                type="text"
                className="form-control mb-3"
                value={shippingAddress}
                onChange={(e) => setShippingAddress(e.target.value)}
                placeholder="Enter your address"
              />
              
              <button className="btn btn-primary btn-lg btn-block">
                Place an order
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToCart;
