import React, { useState, useEffect } from "react";
import cart from "../../assets/icons/bytesize_cart (1).png";
import Kao from "../../assets/icons/kao logo.png";
import { Link, useNavigate } from "react-router-dom";
import menu from "../../assets/icons/mingcute_menu-fill.png";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isLogoVisible, setIsLogoVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const {cart} = useSelector((state) => ({
    cart: state.cartReducer.cart,
  }));

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  // Function to toggle the visibility of the navigation container
  const toggleNav = () => {
    setIsNavVisible((prevState) => !prevState);
  };

  const toggleLogo = () => {
    setIsLogoVisible(!isLogoVisible);
  };

  // Function to handle link click
  const handleLinkClick = () => {
    setIsNavVisible(false); // Close the navigation menu when a link is clicked
  };

  return (
    <header className={"fixed-top pt-2 " + (isScrolled ? "scrolled" : "")}>
      <div className="nav-top container d-flex justify-content-between align-items-center sm-align-items-start">
        <div
          className={isLogoVisible ? "nav-logo p-1" : "nav-logo d-none"}
          onClick={toggleLogo}
        >
          <img src={Kao} style={{ height: "40px" }} alt="Kao logo" />
        </div>

        <div className={`nav-a gap-4 text-light ${isNavVisible ? "show" : ""}`}>
          <Link className="nav-link" id="home" to="/" onClick={handleLinkClick}>
            Home
          </Link>
          <Link
            className="nav-link"
            id="process"
            to="/process"
            onClick={handleLinkClick}
          >
            Process
          </Link>
          <Link
            className="nav-link"
            id="blog"
            to="/blog"
            onClick={handleLinkClick}
          >
            Blog
          </Link>
          <Link className="nav-link" to="/store" onClick={handleLinkClick}>
            Store
          </Link>

          <div className="login-registers text-light gap-2">
            {user ? (
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Welcome, {user.username}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      onClick={logout}
                      className="dropdown-item"
                      to="/logout"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <Link to="/login" onClick={handleLinkClick}>
                  Login
                </Link>{" "}
                /
                <Link to="/register" onClick={handleLinkClick}>
                  Register
                </Link>
              </>
            )}
            <Link to="/cart" onClick={handleLinkClick}>
              <img
                src={cart}
                alt="cart"
                className="px-2"
                style={{ height: "25px" }}
              />
            </Link>
          </div>
        </div>

        <div className="menu" onClick={toggleNav}>
          <img
            src={menu}
            id="menuButton"
            alt="menu"
            style={{ height: "25px", width: "25px" }}
          />
        </div>

        {/* Additional menu for smaller screens */}
        <div
          className={`login-register text-light gap-2 ${
            isNavVisible ? "show" : ""
          }`}
        >
          {user ? (
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Welcome, {user.username}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    onClick={logout}
                    className="dropdown-item"
                    to="/logout"
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <>
              <Link to="/login" onClick={handleLinkClick}>
                Login
              </Link>{" "}
              /
              <Link to="/register" onClick={handleLinkClick}>
                Register
              </Link>
            </>
          )}
          <Link to="/cart" className="m-1" onClick={handleLinkClick}>
            <i className="fa fa-shopping-cart fa-lg"></i>
            <span className="badge rounded-pill badge-notification bg-danger">
              {cart.length}
            </span>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
