import React, { useState } from "react";
import { useDispatch } from 'react-redux'; 
import loginImg from "../../assets/images/Coffee-46.jpg";
import "../login/login.css";
import { Link } from "react-router-dom";
import kao from "../../assets/icons/kao logo.png";
import { loginApi } from "../../Apis/apis";
import { toast } from "react-toastify";
import { addUser } from "../../store/userSlice";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const handelEmail = (e) => {
    setEmail(e.target.value);
  };
  const handelPassword = (e) => {
    setPassword(e.target.value);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    console.log(email, password);
    const data = {
      email: email,
      password: password,
    };
    //  send data to backend
    loginApi(data)
      .then((res) => {
        if (res.data.success == true) {
          toast.success(res.data.message);
          // save token in local storage
          localStorage.setItem("token", res.data.token);
          dispatch(addUser(res.data.userData))
          // converting incomming json
          const convertedJson = JSON.stringify(res.data.userData);
          localStorage.setItem("user", convertedJson);
           // Check if user is admin or not
           if (res.data.userData.isAdmin === true) {
            window.location.href = '/admindashboard';
          } else {
            window.location.href = '/dashboard';
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Internal server error");
      });
  };
  return (
    <>
      <div className="loginImage-back position-absolute">
        <div className="backLoginImg">
          <img src={loginImg} alt="back image" />
        </div>
        <div className="blank">hello</div>
      </div>
      <div className="login-page position-relative">
        <div className="main-container  d-lg-flex d-md-flex d-sm-block ">
          <div className="left-container"></div>
          <div className="right-container  d-flex justify-content-center pt-5">
            <form className=" m-3  p-3">
              <div className="d-flex justify-content-center pb-2">
                <div className="kao-logos">
                  <img src={kao} />
                </div>
              </div>
              <h2 className="text-center">Login</h2>
              <label className="text-white">Email</label>
              <input
                onChange={handelEmail}
                className="form-control mb-2"
                type="name"
                placeholder="Enter your email"
              />
              <label className="text-white">Password</label>
              <input
                onChange={handelPassword}
                className="form-control mb-2"
                type="name"
                placeholder="password "
              />
              <h6 className="text-end text-white pt-2"> forgot password</h6>
              <button className="btn  w-100 " onClick={handelSubmit}>
                login
              </button>
              <h6 className="text-white pt-2">
                Don't have account?{" "}
                <Link to="/register">
                  <span> Sign up</span>
                </Link>
              </h6>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
