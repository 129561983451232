import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Navbar from "./pages/component/Navbar";
import Footer from "./pages/component/Footer";
import Blog from "./pages/Blog";
import Process from "./pages/Process";
import Login from "./pages/login/Login.jsx";
import Product from "./pages/products/Product_detail.jsx";
import Register from "./pages/register/Register.jsx";
import Store from "./pages/store/Store.jsx";
import AddToCart from "./pages/addToCart/AddToCart.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./pages/admin/adminDashboard.jsx";
import AdminRoutes from "./PrivateRoutes/AdminRoutes.jsx";
import UserRoutes from "./PrivateRoutes/UserRoutes.jsx";
import userDashboard from "./pages/user/userDashboard.jsx";
import UserDashboard from "./pages/user/userDashboard.jsx";




function App() {
  return (
    <Router>
      <Navbar />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/process" element={<Process />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/products/:id" element={<Product />} />
        
        <Route path="/store" element={<Store />} />
        <Route path="/cart" element={<AddToCart />} />
        <Route element={<UserRoutes/>}>
          <Route path="/dashboard" element={< UserDashboard />} />
        </Route>
        <Route element={<AdminRoutes />}>
        <Route path="/admindashboard" element={<AdminDashboard />} />
        </Route>
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
      
      <Footer />
    </Router>
  );
}

export default App;
