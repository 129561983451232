import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllProducts } from '../../Apis/apis';
import LikeButton from './LikeButton';
import logo from '../../assets/icons/kao logo.png';

const Product = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getAllProducts();
        setProducts(response.data.products);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
      <div className='container'>
        <div className='gallery col-12 pb-4'>
          <div className='row'>
            {products.map((product) => (
              <div
                key={product._id}
                className='col-lg-3 col-md-6 col-sm-6 col-6 image-container mt-3'
              >
                <div className='store-products p-1'>
                  <div className='store-product-img'>
                  <Link to={`/products/${product._id}`}>
                        <div className="store-product-img">
                          <img
                            src={product.productImage}
                            alt={product.name}
                          />
                          <img
                            src={logo}
                            alt="logo"
                            style={{
                              height: "40px",
                              width: "60px",
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          />
                          <div className="image-text d-flex justify-content-end gap-2">
                           
                            <LikeButton />
                          </div>
                        </div>
                      </Link>
                  </div>
                  <h5 className='pt-3'>{product.name}</h5>
                  <h5 className=''>${product.price}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
