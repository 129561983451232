import React, { useEffect, useState } from "react";
import storeImg1 from "../../assets/images/packet.png";
import "../store/Store.css";
import cart from "../../assets/icons/bytesize_cart (1).png";
import { Link } from "react-router-dom";
import { Fade } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";
import img1 from "../../assets/images/Coffee-105.jpg";
import img2 from "../../assets/images/Coffee-51.jpg";
import img3 from "../../assets/images/Coffee-32.jpg";
import LikeButton from "../component/LikeButton.jsx";
import logo from "../../assets/icons/kao logo.png";
import { getAllProducts } from "../../Apis/apis.js";

const fadeImages = [
  {
    url: img1,
    caption: "First Slide",
  },
  {
    url: img2,
    caption: "Second Slide",
  },
  {
    url: img3,
    caption: "Third Slide",
  },
];

const Store = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProducts()
      .then((res) => {
        console.log(res.data);
        setProducts(res.data.products || []);
      })
      .catch((err) => {
        console.log(err);
        // Handle error
      });
  }, []);

  return (
    <>
      <div className="store-main-body">
        <div className="slide-container">
          <Fade>
            {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                <img
                  style={{ width: "100%", height: "70vh" }}
                  src={fadeImage.url}
                />
              </div>
            ))}
          </Fade>
        </div>
        {/* <div className='store-img'>
    <img src={storeImg1} alt="store image"/>
    </div> */}
<div className="store-container">
          <div className="container">
            <h5 className="text-end pt-3">KAO Store</h5>
            <hr className="solid" />
            <div className="gallery col-12 pb-4">
              <div className="row">
              {products.map((product) => (
                  <div
                    key={product._id}
                    className="col-lg-3 col-md-6 col-sm-6 col-6 image-container  mt-3"
                  >
                    <div className="store-products p-1">
                      <Link to={`/products/${product._id}`}>
                        <div className="store-product-img">
                          <img
                            src={product.productImage}
                            alt={product.name}
                          />
                          <img
                            src={logo}
                            alt="logo"
                            style={{
                              height: "40px",
                              width: "60px",
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          />
                          <div className="image-text d-flex justify-content-end gap-2">
                            <img src={cart} alt="cart" />
                            <LikeButton />
                          </div>
                        </div>
                      </Link>
                      <h5 className="pt-3">{product.name}</h5>
                      <h5 className="">{`Rs. ${product.price}`}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        </div>
      
    </>
  );
};

export default Store;
