import React, { useEffect, useState } from "react";
import {
  createCategoryApi,
  createProductApi,
 deleteProductById,
  getAllCategoryApi,
  getAllProducts,
} from "../../Apis/apis";
import { toast } from "react-toastify";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("viewProducts");
  const [previewImage, setPreviewImage] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    getAllCategoryApi()
      .then((res) => {
        console.log(res.data);
        setCategory(res.data || []);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to fetch categories");
      });
  }, []);

  useEffect(() => {
    getAllProducts()
      .then((res) => {
        console.log(res.data);
        setProducts(res.data.products || []);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to fetch products");
      });
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleImage = (event) => {
    setProductImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handelCategoryAdd = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", categoryName);
    createCategoryApi(formData)
      .then((res) => {
        if (res.data.success === false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Internal server error");
      });
  };

  const handleProductAdd = (e) => {
    e.preventDefault();
    console.log("Selected Category:", selectedCategory);

    // Find the selected category object
    const selectedCategoryObject = category.find(
      (cat) => cat.name === selectedCategory.trim()
    );

    // Check if the category exists
    if (!selectedCategoryObject) {
      toast.error("Selected category not found");
      return;
    }

    console.log("Selected Category Object:", selectedCategoryObject);

    const categoryId = selectedCategoryObject._id; // Get the ObjectId of the selected category

    const formData = new FormData();
    formData.append("name", productName);
    formData.append("description", productDescription);
    formData.append("price", productPrice);
    formData.append("category", categoryId); // Send the ObjectId of the selected category

    console.log(productName, productPrice, productDescription, categoryId);

    // send request to backend API
    createProductApi(formData)
      .then((res) => {
        if (res.data.success === false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Internal server error");
      });
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between">
        <h3>Admin Dashboard</h3>
      </div>
      <div className="row row-cols-1 row-cols-md-4 g-4">
        <div className="col">
          <div class="card text-white bg-danger mb-3">
            <div class="card-header">Total products</div>
            <div class="card-body">
              <h1>12</h1>
            </div>
          </div>
        </div>
        <div className="col">
          <div class="card text-white bg-warning mb-3">
            <div class="card-header">Total pending Orders</div>
            <div class="card-body">
              <h1>12</h1>
            </div>
          </div>
        </div>
        <div className="col">
          <div class="card text-white bg-success mb-3">
            <div class="card-header">Total delivered orders</div>
            <div class="card-body">
              <h1>12</h1>
            </div>
          </div>
        </div>
        <div className="col">
          <div class="card text-white bg-success mb-3">
            <div class="card-header">Total users</div>
            <div class="card-body">
              <h1>12</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="list-group">
            <button
              className={`list-group-item list-group-item-action ${
                activeTab === "viewProducts" ? "active" : ""
              }`}
              onClick={() => handleTabChange("viewProducts")}
            >
              View Products
            </button>
            <button
              className={`list-group-item list-group-item-action ${
                activeTab === "addCategory" ? "active" : ""
              }`}
              onClick={() => handleTabChange("addCategory")}
            >
              Add Category
            </button>

            <button
              className={`list-group-item list-group-item-action ${
                activeTab === "addProduct" ? "active" : ""
              }`}
              onClick={() => handleTabChange("addProduct")}
            >
              Add Product
            </button>
            <button
              className={`list-group-item list-group-item-action ${
                activeTab === "addBlog" ? "active" : ""
              }`}
              onClick={() => handleTabChange("addBlog")}
            >
              Add Blog
            </button>
            <button
              className={`list-group-item list-group-item-action ${
                activeTab === "checkOrders" ? "active" : ""
              }`}
              onClick={() => handleTabChange("checkOrders")}
            >
              Check Orders
            </button>
          </div>
        </div>
        <div className="col-md-9">
          {activeTab === "viewProducts" && (
            <table className="table mt-2">
              <thead className="table-info">
                <tr>
                  <th>Image</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Product Price</th>
                  <th scope="col">Product Category</th>
                  <th scope="col">Product Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product._id}>
                    <td>
                      <img
                        src={product.productImage}
                        className="img-fluid"
                        alt=""
                        height={30}
                        width={30}
                      />
                    </td>
                    <td>{product.name}</td>
                    <td>{product.price}</td>
                    <td>{product.category.name}</td>
                    <td>{product.description}</td>
                    <td>
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button type="button" className="btn btn-success">
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          // onClick={() => handleDeleteProduct(product._id)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {activeTab === "addCategory" && (
            <div className="card">
              <div className="card-body">
                {/* Add category form */}
                <h5 className="card-title">Add Category</h5>
                <form onSubmit={handelCategoryAdd}>
                  <div className="mb-3">
                    <label htmlFor="categoryName" className="form-label">
                      Category Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="categoryName"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Add Category
                  </button>
                </form>
              </div>
            </div>
          )}

          {activeTab === "addProduct" && (
            <div className="card">
              <div className="card-body">
                {/* Add product form */}
                <h5 className="card-title">Add Product</h5>
                <form onSubmit={handleProductAdd}>
                  <div className="mb-3">
                    <label htmlFor="productName" className="form-label">
                      Product Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="productName"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      placeholder="Enter Product Name"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="productPrice" className="form-label">
                      Product Price
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="productPrice"
                      value={productPrice}
                      onChange={(e) => setProductPrice(e.target.value)}
                      placeholder="Enter Product Price"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="productCategory" className="form-label">
                      Product Category
                    </label>
                    <select
                      className="form-select"
                      id="productCategory"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {category.map((cat) => (
                        <option key={cat._id} value={cat.name}>
                          {cat.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="productDescription" className="form-label">
                      Product Description
                    </label>
                    <textarea
                      className="form-control"
                      id="productDescription"
                      rows="4"
                      value={productDescription}
                      onChange={(e) => setProductDescription(e.target.value)}
                      placeholder="Enter Product Description"
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="productImage" className="form-label">
                      Product Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="productImage"
                      onChange={handleImage}
                    />
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Product Preview"
                        className="img-fluid mt-2"
                        style={{ maxHeight: "200px" }}
                      />
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Add Product
                  </button>
                </form>
              </div>
            </div>
          )}

          {activeTab === "addBlog" && (
            <div className="card">
              <div className="card-body">
                {/* Add blog form */}
                <h5 className="card-title">Add Blog</h5>
                <form>
                  {/* Add form fields for adding blog */}
                  {/* Include code for adding blog similar to what you had in the modal */}
                </form>
              </div>
            </div>
          )}
          {activeTab === "checkOrders" && (
            <div className="card">
              <div className="card-body">
                {/* Check orders section */}
                <h5 className="card-title">Check Orders</h5>
                <table className="table mt-2">
                  <thead className="table-info">
                    <tr>
                      <th>Order ID</th>
                      <th>Name</th>
                      <th>Shipping Address</th>
                      <th>Total Amount</th>
                      <th>Payment ID</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>John Doe</td>
                      <td>123 Main St, City, Country</td>
                      <td>$100</td>
                      <td>ABC123XYZ</td>
                      <td>
                        <select className="form-select">
                          <option value="pending">Pending</option>
                          <option value="shipped">Shipped</option>
                          <option value="delivered">Delivered</option>
                        </select>
                      </td>
                      <td>
                        <button type="button" className="btn btn-primary">
                          Update Status
                        </button>
                      </td>
                    </tr>
                    {/* Add more rows for other orders */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
