import axios from "axios";
const Api = axios.create({
    baseURL: "https://kao-nepal-backend.onrender.com",
    // baseURL: "http://localhost:5000",
    withCredentials: true,
    headers: {
        "Content-Type": "multipart/form-data",
    },
});
//configurations for axios
const config = {
    headers: {
        "authorization": `Bearer ${localStorage.getItem("token")}`,
    },
};
export const loginApi= (data) => Api.post("/api/user/login", data);
export const registerApi= (data) => Api.post("/api/user/create", data);
export const createProductApi=(formData)=>Api.post("/api/product/createProduct",formData,config);    
export const createCategoryApi=(formData)=>Api.post("/api/category/createCategory",formData,config);
export const getAllCategoryApi=()=>Api.get("/api/category/get_categories",config);
export const getAllProducts=()=>Api.get("/api/product/get_products",config);
export const deleteProductById=(id)=>Api.delete(`/api/product/delete_products/${id}`,config);
export const getProductById=(id)=>Api.get(`/api/product/get_product/${id}`,config);