import React, { useState, useEffect } from 'react';
import './slider.css'; // Assuming this is the CSS file for styling
import img4 from "../../assets/images/Screenshot (147).jpg";
import img2 from "../../assets/images/Screenshot (148).jpg";
import img3 from "../../assets/images/Screenshot (151).jpg";

import img1 from "../../assets/images/Screenshot (157).jpg";

const ImageGallery = () => {
  // State to keep track of the currently selected image
  const [selectedImage, setSelectedImage] = useState(0); // Set default to the first image

  // Array of image URLs and corresponding text
  const images = [
    { src: img1, text: "Text for image 1" },
    { src: img2, text: "Text for image 2" },
    { src: img3, text: "Text for image 3" },
    { src: img4, text: "Text for image 4" },
  
  ];

  // Function to handle click event on thumbnails
  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };

  // Effect to change the big image every 3 seconds in a continuous loop
  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedImage(prevIndex => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval); // Cleanup function to clear the interval on component unmount
  }, [images.length]); // Dependency array ensures the effect runs only when images changes

  return (
    <div className="image-gallery">
      {/* Big Image */}
      <div className="big-image position-relative">
        <img src={images[selectedImage].src} alt="Big" />
        <div className="image-text pb-5" style={{position:"absolute",bottom:"0" ,left:"5%",color:"white",fontSize:"25px"}}>{images[selectedImage].text}</div>
      </div>

      {/* Thumbnail Images */}
      <div className="thumbnail-images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={`Thumbnail ${index}`}
            onClick={() => handleThumbnailClick(index)}
            className={selectedImage === index ? 'selected' : ''}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
