import React, { useState } from "react";
import loginImgs from "../../assets/images/Coffee-46.jpg";
import kao from "../../assets/icons/kao logo.png";
import { Link } from "react-router-dom";
import { registerApi } from "../../Apis/apis";
import { toast } from "react-toastify";

const Register = () => {
  const [name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const handelFullName = (e) => {
    setFullName(e.target.value);
  };
  const handelEmail = (e) => {
    setEmail(e.target.value);
  };
  const handelPhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handelPassword = (e) => {
    setPassword(e.target.value);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, phone, password);
    const data = {
      name: name,
      email: email,
      phone: phone,
      password: password,
    };
    registerApi(data)
      .then((res) => {
        if (res.data.success == true) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Internal server error");
      });
  };

  return (
    <>
      <div className="loginImage-back position-absolute">
        <div className="backLoginImg">
          <img src={loginImgs} alt="back-image" />
        </div>
        <div className="blank">hello</div>
      </div>
      <div className="login-page position-relative">
        <div className="main-container  d-lg-flex d-md-flex d-sm-block ">
          <div className="left-container"></div>
          <div className="right-container  d-flex justify-content-center pt-2">
            <form className=" m-3  p-3">
              <div className="d-flex justify-content-center pb-2">
                <div className="kao-logos">
                  <img src={kao} />
                </div>
              </div>

              <h2 className="text-center">Register</h2>
              <label className="text-white">Full Name</label>
              <input
                onChange={handelFullName}
                className="form-control mb-2"
                type="text"
                placeholder="Enter your full name"
              />
              <label className="text-white">Email</label>
              <input
                onChange={handelEmail}
                className="form-control mb-2"
                type="text"
                placeholder="Enter your email"
              />
              <label className="text-white">Phone Number</label>
              <input
                onChange={handelPhoneNumber}
                className="form-control mb-2"
                type="name"
                placeholder="Enter your phone number"
              />
              <label className="text-white">Password</label>
              <input
                onChange={handelPassword}
                className="form-control mb-2"
                type="name"
                placeholder="password "
              />
              <h6 className="text-end text-white"> forgot password</h6>
              <button className="btn  w-100" onClick={handelSubmit}>
                Sign Up
              </button>
              <h6 className="text-white pt-2">
                Login already?{" "}
                <Link to="/login" className="links">
                  Sign in
                </Link>
              </h6>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
