import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cart: [],
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const { id, quantity } = action.payload;
            const existingItem = state.cart.find((item) => item.id === id);
      
            if (existingItem) {
              // If the item already exists in the cart, update its quantity
              existingItem.quantity += quantity;
            } else {
              // Otherwise, add it to the cart
              state.cart.push(action.payload);
            }
        },
        increaseQuantity: (state, action) => {
            const { itemId } = action.payload;
            const item = state.cart.find((item) => item.id === itemId);
            if (item) {
              item.quantity += 1;
            }
          },
          decreaseQuantity: (state, action) => {
            const { itemId } = action.payload;
            const item = state.cart.find((item) => item.id === itemId);
            if (item && item.quantity > 1) { // Change item.quantity > 0 to item.quantity > 1
              item.quantity -= 1;
            }
          },
          
        removeFromCart: (state, action) => {
            const {itemId} = action.payload;
            state.cart = state.cart.filter((item) => item.id !== itemId);
        },
    },
});

export const { addToCart, removeFromCart,increaseQuantity,decreaseQuantity } = cartSlice.actions;
export default cartSlice.reducer; // Make sure to export the default reducer correctly
